<template>
  <div class="flex h-full flex-col">
    <!-- HEADER -->
    <div
      class="sticky top-0 z-10 mr-1 flex h-10 w-full items-center justify-between bg-white md:mt-2 mb-2"
    >
      <div class="flex items-center">
        <ShoppingBagIcon class="w-6" />
        <h3 class="ml-1 text-xl font-normal">Carro de compras</h3>
      </div>
      <div class="text-right" v-if="$cantidadItems">
        <p class="text-gray-500 text-sm md:mb-1">
          {{ $cantidadItems }} productos
        </p>
        <p
          class="hidden md:block text-xs text-red-500 font-semibold underline underline-offset-2 cursor-pointer"
          @click="clearCart"
        >
          VACIAR CARRITO
        </p>
      </div>
    </div>
    <div
      class="bg-gray-100 rounded py-2 px-2.5 mb-2"
      v-if="$ventanaActiva && despachoManana"
    >
      <p class="text-xs leading-none font-normal text-gray-800 mb-1">
        Recibirás tu pedido en 24 horas o menos por ciertos productos.
      </p>
      <div class="flex items-center">
        <TruckIcon class="min-w-5 w-5 mr-2 text-green-500" />
        <p class="text-sm leading-5 font-normal">
          Horario de despacho:
          <span class="font-bold">{{
            fechaRangoHoraShort(
              $ventanaActiva.fecha,
              $ventanaActiva.inicio,
              $ventanaActiva.termino,
            )
          }}</span>
        </p>
      </div>
    </div>
    <div
      class="text-sm leading-5 font-normal pb-2"
      :class="{
        'hidden md:block': despachoGratisDesde - $totalCarro <= 0,
      }"
      v-if="despachoGratisDesde > 0"
    >
      <p v-if="despachoGratisDesde - $totalCarro > 0">
        Compra {{ moneda(despachoGratisDesde - $totalCarro) }} más para obtener
        despacho gratis
      </p>
      <p v-else>¡Felicidades! Tienes despacho gratis</p>
      <ProgressBar
        class="mt-0.5"
        :desde="0"
        :hasta="despachoGratisDesde"
        :total="$totalCarro"
      />
    </div>
    <!-- CART ITEMS -->
    <div class="scrollbar flex-1 overflow-auto" v-if="$cantidadItems">
      <Item
        class="my-2 mr-1 sm:my-4"
        v-for="item in $itemsCarro"
        :key="item.producto_id"
        :item="item"
      />
    </div>
    <!-- TOTAL, SHIPPING & BUTTON -->
    <div
      v-if="$cantidadItems"
      class="sticky bottom-0 flex flex-col bg-white pt-2 pb-8"
    >
      <div class="flex justify-between text-lg leading-none mb-3">
        <span>Sub-total:</span>
        <div class="flex flex-col">
          <span class="text-green-500 leading-7 font-bold text-right">{{
            moneda($subtotalCarro)
          }}</span>
          <span
            class="text-sm leading-5 font-medium text-red-500"
            v-if="$ahorroCarro"
            >Estás ahorrando <b>-{{ moneda($ahorroCarro) }}</b></span
          >
        </div>
      </div>
      <div class="flex justify-between text-lg leading-none mb-4">
        <span>Despacho:</span>
        <div class="flex flex-col">
          <span class="text-green-500 leading-7 text-right">{{
            moneda($costoDespacho)
          }}</span>
        </div>
      </div>
      <div
        class="flex justify-between text-sm leading-5 font-normal text-red-500 mb-3 items-center"
        v-if="despachoGratisDesde > 0 && $totalCarro >= despachoGratisDesde"
      >
        <div class="flex items-center">
          <EstrellaFrestar v-if="$user?.frestar" class="w-5 h-5 mr-3" />
          <CuponIcon v-else class="w-5 h-5 mr-3 !stroke-red-500" />
          <p>Despacho gratis:</p>
        </div>
        <div class="text-lg">-{{ moneda($costoDespacho) }}</div>
      </div>
      <div class="flex justify-between text-lg leading-none mb-4">
        <span>Total Pedido:</span>
        <div class="flex flex-col">
          <span class="text-green-500 leading-7 font-bold text-right">{{
            moneda(total)
          }}</span>
        </div>
      </div>
      <div v-if="pathname === '/checkout'">
        <Button
          class="mb-3 h-10 w-full"
          size="l"
          type="primary"
          @click="continuarPago"
        >
          Continuar con el pago
        </Button>
      </div>
      <div v-else>
        <Button
          class="mb-3 h-10 w-full"
          size="l"
          type="primary"
          @click="irPagar"
          :loading="$loadingIrAPagar"
        >
          Ir a pagar
        </Button>
        <Button
          size="l"
          class="hidden w-full border-none shadow-md sm:block"
          @click="closeCart"
          >Seguir comprando</Button
        >
      </div>
    </div>

    <div class="mb-40 flex flex-1 flex-col items-center justify-center" v-else>
      <picture>
        <source srcset="/assets/carro/brocoli-triste.webp" type="image/webp" />
        <img
          src="/assets/carro/brocoli-triste.png"
          alt="Carro de compras vacío"
          class="mx-auto mb-4 w-10/12"
        />
      </picture>
      <span class="pb-2 text-base font-semibold"
        >Tu carro de compras está vacío</span
      >
      <Button @click="closeCart" class="h-10 w-full" size="xl" type="primary">
        Ver productos
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
  import Item from "@components/carro/CartItem.vue";
  import { ShoppingBagIcon, TruckIcon } from "@heroicons/vue/24/outline";
  import { useStore } from "@nanostores/vue";
  import {
    cantidadItems,
    closeCart,
    itemsCarro,
    totalCarro,
    subtotalCarro,
    ahorroCarro,
    clearCart,
    loadingIrAPagar,
    hayProductosMayoritas,
  } from "@stores/carro";
  import {
    direccionActiva,
    costoDespacho,
    restringirRetiroTienda,
    abrirModalDespachoLogin,
    ventanaActiva,
  } from "@stores/despacho";
  import Button from "@ui/Button.vue";
  import { moneda, fechaRangoHoraShort } from "@lib/formatters";
  import { computed } from "@vue/runtime-core";
  import { cuponBienvenidaId, beneficioDespachoFrestarId } from "@stores/app";
  import { user } from "@stores/user";
  import ProgressBar from "./ProgressBar.vue";
  import EstrellaFrestar from "@components/iconos/EstrellaFrestar.vue";
  import CuponIcon from "@components/iconos/CuponIcon.vue";
  import { continueCheckoutOnCart, goToCheckoutCart } from "@lib/eventos";

  const $itemsCarro = useStore(itemsCarro);
  const $cantidadItems = useStore(cantidadItems);
  const $direccionActiva = useStore(direccionActiva);
  const $totalCarro = useStore(totalCarro);
  const $subtotalCarro = useStore(subtotalCarro);
  const $ahorroCarro = useStore(ahorroCarro);
  const $costoDespacho = useStore(costoDespacho);
  const $user = useStore(user);
  const $cuponBienvenidaId = useStore(cuponBienvenidaId);
  const $beneficioDespachoFrestarId = useStore(beneficioDespachoFrestarId);
  const $loadingIrAPagar = useStore(loadingIrAPagar);
  const $restringirRetiroTienda = useStore(restringirRetiroTienda);
  const $hayProductosMayoritas = useStore(hayProductosMayoritas);
  const $ventanaActiva = useStore(ventanaActiva);

  const continuarPago = () => {
    closeCart();
    continueCheckoutOnCart();
  };

  const irPagar = () => {
    avanzarCarrito();
    goToCheckoutCart();
  };

  const { pathname } = window.location;
  const avanzarCarrito = () => {
    if (!$direccionActiva.value) {
      abrirModalDespachoLogin(undefined, redireccionar);
      return closeCart();
    }
    redireccionar();
  };

  const redireccionar = () => {
    window.location.href = "/checkout";
  };

  const despachoGratisDesde = computed(() => {
    if (!$user.value) return 0;
    if ($user.value.frestar) {
      const beneficio = $user.value.beneficios.find(
        (beneficio) => beneficio.id === $beneficioDespachoFrestarId.value,
      );
      if (beneficio) return (beneficio.parametros?.desde as number) || 0;
    }
    if (!$user.value.cantidad_pedidos) {
      const cuponBienvenida = $user.value.cupones_validos.find(
        (cupon) => cupon.id === $cuponBienvenidaId.value,
      );
      if (cuponBienvenida) return cuponBienvenida.total_desde || 0;
    }
    return 0;
  });

  const total = computed(() => {
    if (
      despachoGratisDesde.value > 0 &&
      $totalCarro.value >= despachoGratisDesde.value
    )
      return $totalCarro.value;
    return $totalCarro.value + $costoDespacho.value;
  });

  const despachoManana = computed(() => {
    if ($restringirRetiroTienda.value) return true;
    if ($hayProductosMayoritas.value) return true;
    return false;
  });
</script>
